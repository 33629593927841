<template>
  <div :class="['page', { isOld: isOld == 1 ? true : false }]">
    <img src="./img/yellow-bg.png" class="bg" />
    <!-- <div class="bottom-box">
      <div class="box" @click="showDialog = true">
        <div class="left">
          <img src="./img/rice-icon.png" alt="" />
          <div class="redBox">{{ dialogList.length }}</div>
        </div>
        <div class="center"><span>¥</span>24</div>
        <div class="right" @click="toPath('chooseDiningMethod')">去结算</div>
      </div>
    </div> -->
    <div class="main">
      <div class="placeBox" v-if="type == '外送'" @click="showDialog1 = true">
        <div class="top">
          {{ receiverAddress }}
          <img src="./img/arrow-r.png" alt="" />
        </div>
        <div class="center">{{ receiverName }} {{ receiverPhone }}</div>
        <div class="bottom">请确认配送地址以及联系方式</div>
      </div>
      <div class="orderDetail" v-else>
        <div class="orderDetail-top">
          <div class="title">您已选择堂食～</div>
        </div>
      </div>
      <div class="orderDetail">
        <div class="orderDetail-top">
          <div class="title">订单详情</div>
        </div>
        <div class="orderDetail-center">
          <div class="item" v-for="(item, index) in dialogList" :key="index">
            <div class="item-left">
              <img :src="item.image" alt="" />
            </div>
            <div class="item-right">
              <div class="name">
                <div class="name-txt">{{ item.foodName }}</div>
                <div class="price"><span>¥</span>{{ item.countPrice }}</div>
              </div>
              <div class="num">x1</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="black-bg"
      v-if="showDialog1 || showDialog"
      @click="
        showDialog1 = false;
        showDialog = false;
      "
    >
      <div class="dialog">
        <div class="top">
          <div
            class="item"
            @click="checkPlace(item)"
            v-for="(item, index) in assetList"
            :key="index"
          >
            <div class="item-top">
              <img v-if="item.isChosen" src="./img/check.png" alt="" />
              <img v-else src="./img/check-no.png" alt="" />

              {{ item.houseName }}
            </div>
            <div class="item-bottom">
              {{ receiverName }} {{ receiverPhone }}
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="btn" @click="selectPlace()">确定</div>
        </div>
      </div>
    </div>
    <div class="submitbtn" @click="submit">{{ "结算" }}</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  clearMyCartUrl,
  getMyCartUrl,
  orderFoodUrl,
  deleteCartFoodUrl,
  userAssetListUrl,
} from "./api";
export default {
  name: "payFoodOrder",
  data() {
    return {
      showDialog1: false,
      showDialog: false,
      dialogList: [],
      type: null,
      oldPersonId: null,
      receiverName: null,
      receiverPhone: null,
      sumPrice: null,
      receiverAddress: null,
      houseId: null,
      assetList: [],
      isOld: localStorage.getItem("isOld"),
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId"]),
  },
  created() {
    this.type = this.$route.query.type;
    this.oldPersonId = this.$route.query.oldPersonId;
    this.receiverName = this.$route.query.receiverName;
    this.receiverPhone = this.$route.query.receiverPhone;
    this.getDialogList();
    this.getuserAssetList();
  },
  methods: {
    checkPlace(data) {
      this.assetList.forEach((item) => {
        item.isChosen = 0;
      });
      data.isChosen = 1;
      this.receiverAddress = data.houseName;
      this.houseId = data.houseId;
      this.showDialog1 = false;
    },
    getuserAssetList() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios.post(`${userAssetListUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.assetList = res.data;
          this.assetList.forEach((item) => {
            if (item.isChosen) {
              this.receiverAddress = item.houseName;
              this.houseId = item.houseId;
            }
          });
        }
      });
    },
    submit() {
      let params = {
        userId: this.userId,
        countPrice: this.sumPrice,
        orderType: this.type == "堂食" ? 1 : 2,
        oldPersonId: this.oldPersonId,
        receiverName: this.receiverName,
        receiverPhone: this.receiverPhone,
        receiverAddress: this.type == "堂食" ? null : this.receiverAddress,
        houseId: this.type == "堂食" ? null : this.houseId,
        details: this.dialogList,
        tenantId: this.tenantId,
      };
      this.$axios.post(`${orderFoodUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.$router.replace({
            name: "deliverySuccess",
            query: {
              code: res.data,
            },
          });
        }
      });
    },
    // delFood(item) {
    //   let params = {
    //     id: item.id,
    //   };
    //   this.$axios
    //     .post(`${deleteCartFoodUrl}`, this.$qs.stringify(params))
    //     .then((res) => {
    //       if (res.code == 200) {
    //         this.getDialogList();
    //       }
    //     });
    // },
    // // 清空购物车
    // clearShopCar() {
    //   let params = {
    //     userId: this.userId,
    //   };
    //   this.$axios
    //     .post(`${clearMyCartUrl}`, this.$qs.stringify(params))
    //     .then((res) => {
    //       if (res.code == 200) {
    //         this.getDialogList();
    //       }
    //     });
    // },
    getDialogList() {
      let params = {
        userId: this.userId,
      };
      this.$axios.get(`${getMyCartUrl}`, { params: params }).then((res) => {
        if (res.code == 200) {
          this.dialogList = res.data;
          let priceList = [];
          this.dialogList.forEach((t) => {
            priceList.push(Number(t.countPrice));
          });
          this.sumPrice = this.sum(priceList);
        }
      });
    },
    // 数组求和
    sum(arr) {
      return arr.reduce(function (total, value) {
        return total + value;
      }, 0);
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  box-sizing: border-box;
  background: #f9f9f9;
  .submitbtn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 114px;
    left: 50%;
    transform: translateX(-50%);
  }
  .black-bg {
    position: fixed;
    z-index: 10000000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.41);
    .dialog {
      width: 100%;
      height: 692px;
      background: #ffffff;
      border-radius: 16px 16px 0 0;
      position: absolute;
      bottom: 0;
      .top {
        height: 500px;
        padding: 0 30px;
        box-sizing: border-box;
        overflow-y: scroll;
        .item {
          width: 100%;
          height: 172px;
          border-bottom: 2px solid #f5f5f5;
          .item-bottom {
            font-size: 32px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            padding-left: 42px;
            margin-top: 6px;
          }
          .item-top {
            display: flex;
            align-items: center;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            padding-top: 34px;
            box-sizing: border-box;
            img {
              width: 30px;
              margin-right: 12px;
            }
          }
        }
      }
      .bottom {
        height: calc(100% - 500px);
        .btn {
          width: 550px;
          height: 66px;
          background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
          border-radius: 10px;
          line-height: 66px;
          text-align: center;
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin: 40px auto 0;
        }
      }
    }
  }
  .main {
    padding: 24px 30px 0;
    box-sizing: border-box;
    .placeBox {
      width: 100%;
      background: #ffffff;
      border-radius: 16px;
      position: relative;
      margin-bottom: 22px;
      padding: 34px 40px 20px 30px;
      box-sizing: border-box;
      .bottom {
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f7ca49;
        margin-top: 10px;
      }
      .center {
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
      .top {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        img {
          width: 12px;
        }
      }
    }
    .orderDetail {
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 20px;
      position: relative;
      .orderDetail-center {
        padding: 28px 32px 5px;
        box-sizing: border-box;
        border-bottom: 2px solid #f5f5f5;
        .item {
          height: 136px;
          width: 100%;
          display: flex;
          margin-bottom: 38px;
          .item-right {
            flex: 1;
            padding: 14px 0 0 20px;
            box-sizing: border-box;
            .num {
              font-size: 32px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            }
            .name {
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              .name-txt {
                flex: 1;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
              }
              .price {
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #e02020;
                span {
                  font-size: 24px;
                }
              }
            }
          }
          .item-left {
            width: 136px;
            height: 136px;
            border-radius: 12px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .orderDetail-top {
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 30px;
        display: flex;
        align-items: center;
        height: 100px;
        border-bottom: 2px solid #f5f5f5;
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .bottom-box {
    z-index: 100;
    width: 100%;
    position: fixed;
    height: 232px;
    bottom: 0;
    background: #ffffff;
    box-sizing: border-box;
    padding: 40px 30px 96px;
    .box {
      width: 100%;
      height: 96px;
      display: flex;
      border-radius: 51px;
      background: #0e0d0c;
      align-items: center;
      padding-left: 26px;
      box-sizing: border-box;
      overflow: hidden;
      .center {
        flex: 1;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        height: 100%;
        line-height: 96px;
        padding-left: 32px;
        box-sizing: border-box;
        span {
          font-size: 28px;
        }
      }
      .right {
        width: 196px;
        height: 100%;
        background: linear-gradient(180deg, #fed143 0%, #ffca43 100%);
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        text-align: center;
        line-height: 96px;
      }
      .left {
        width: 106px;
        height: 100%;
        position: relative;
        .redBox {
          position: absolute;
          right: -4px;
          top: 8px;
          width: 40px;
          height: 40px;
          background: #fe3c00;
          border-radius: 50%;
          text-align: center;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
        img {
          display: block;
          margin-top: 6px;
          width: 100%;
          height: 90px;
        }
      }
    }
  }
  .bg {
    width: 100%;
    height: 982px;
    position: fixed;
    top: 0;
  }
}
.isOld {
  .submitbtn {
    width: 550px;
    height: 86px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 86px;
    font-size: 46px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 114px;
    left: 50%;
    transform: translateX(-50%);
  }
  .black-bg {
    position: fixed;
    z-index: 10000000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.41);
    .dialog {
      width: 100%;
      height: 692px;
      background: #ffffff;
      border-radius: 16px 16px 0 0;
      position: absolute;
      bottom: 0;
      .top {
        height: 500px;
        padding: 0 30px;
        box-sizing: border-box;
        overflow-y: scroll;
        .item {
          width: 100%;
          height: 172px;
          border-bottom: 2px solid #f5f5f5;
          .item-bottom {
            font-size: 32px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            padding-left: 42px;
            margin-top: 6px;
          }
          .item-top {
            display: flex;
            align-items: center;
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            padding-top: 34px;
            box-sizing: border-box;
            img {
              width: 30px;
              margin-right: 12px;
            }
          }
        }
      }
      .bottom {
        height: calc(100% - 500px);
        .btn {
          width: 550px;
          height: 66px;
          background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
          border-radius: 10px;
          line-height: 66px;
          text-align: center;
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin: 40px auto 0;
        }
      }
    }
  }
  .main {
    padding: 24px 30px 0;
    box-sizing: border-box;
    .placeBox {
      width: 100%;

      background: #ffffff;
      border-radius: 16px;
      position: relative;
      margin-bottom: 22px;
      padding: 34px 40px 20px 30px;
      box-sizing: border-box;
      .bottom {
        font-size: 42px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f7ca49;
        margin-top: 10px;
      }
      .center {
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
      .top {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 50px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        img {
          width: 12px;
        }
      }
    }
    .orderDetail {
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 20px;
      position: relative;
      .orderDetail-center {
        padding: 28px 32px 5px;
        box-sizing: border-box;
        border-bottom: 2px solid #f5f5f5;
        .item {
          height: 136px;
          width: 100%;
          display: flex;
          margin-bottom: 48px;
          .item-right {
            flex: 1;
            padding: 0 0 0 20px;
            box-sizing: border-box;
            .num {
              font-size: 42px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            }
            .name {
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              .name-txt {
                flex: 1;
                font-size: 50px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
              }
              .price {
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #e02020;
                span {
                  font-size: 34px;
                }
              }
            }
          }
          .item-left {
            width: 156px;
            height: 156px;
            border-radius: 12px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .orderDetail-top {
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 30px;
        display: flex;
        align-items: center;
        height: 100px;
        border-bottom: 2px solid #f5f5f5;
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .bottom-box {
    z-index: 100;
    width: 100%;
    position: fixed;
    height: 232px;
    bottom: 0;
    background: #ffffff;
    box-sizing: border-box;
    padding: 40px 30px 96px;
    .box {
      width: 100%;
      height: 96px;
      display: flex;
      border-radius: 51px;
      background: #0e0d0c;
      align-items: center;
      padding-left: 26px;
      box-sizing: border-box;
      overflow: hidden;
      .center {
        flex: 1;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        height: 100%;
        line-height: 96px;
        padding-left: 32px;
        box-sizing: border-box;
        span {
          font-size: 28px;
        }
      }
      .right {
        width: 196px;
        height: 100%;
        background: linear-gradient(180deg, #fed143 0%, #ffca43 100%);
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        text-align: center;
        line-height: 96px;
      }
      .left {
        width: 106px;
        height: 100%;
        position: relative;
        .redBox {
          position: absolute;
          right: -4px;
          top: 8px;
          width: 40px;
          height: 40px;
          background: #fe3c00;
          border-radius: 50%;
          text-align: center;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
        img {
          display: block;
          margin-top: 6px;
          width: 100%;
          height: 90px;
        }
      }
    }
  }
  .bg {
    width: 100%;
    height: 982px;
    position: fixed;
    top: 0;
  }
  .title {
    font-size: 42px;
  }
}
</style>
